

export enum NetworkEndpoints {
    AGENCIES = 'agencies',
    ADMIN_AGENCIES = 'agencies/internal',
    ADMIN_INSPECTION = 'verifications/awaiting-inspection/internal',
    ADMIN_INSPECTION_DONE = 'verifications/inspected/internal',
    ADMIN_INVITE = 'tokens/roles/admin/purposes/registration-invite/internal',
    ADMIN_ADMIN_INVITE = 'invite/admin',
    ADMIN_TOKEN_REPACK = 'tokens/roles/admin/purposes/registered-access/repackage/internal',
    BALANCE = 'credits/deposits/agencies',
    USER = 'users',
    USERS = 'users/all',
    USER_INVITE = 'credentials/agencies',
    USER_PENDING_INVITE = 'credentials/invites',
    PASSWORD_RESET_INVITE = 'users/password/reset/internal',
    PASSWORD_RESET = 'users/password/reset',
    PASSWORD_SET = 'users/password/set',
    REGISTRATION = 'signup',

    CANDIDATES = 'candidates/all',
    CANDIDATE = 'candidates',
    CANDIDATE_BY_ID = 'candidates/full',
    EXPERIENCES = 'experiences',
    EXPERIENCE_BY_ID = 'experiences/ids',
    VERIFICATION = 'verifications',
    VERIFICATION_BY_EXPERIENCE = 'verifications/experiences',
    VERIFICATION_BY_ID = 'verifications/ids',
    INVITATION_CANDIDATE = 'credentials/candidates',
    CONSENT_CANDIDATE = 'candidates/consent/candidate',
    LOGIN = 'login',
    REFRESH_TOKEN = 'refresh',

    CERTIFICATION = 'candidates/certificates'

}