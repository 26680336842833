import {useNavigate, useParams} from "react-router-dom"
import {useMutation, useQuery, useQueryClient} from "react-query"
import {NetworkEndpoints} from "../../models/NetworkEndpoints"
import {getQueryByTempTokenNetwork, getQueryNetwork} from "../../networks/getNetwork"
import {postExperienceNetwork} from "../../networks/postNetwork"
import {useContext, useRef} from "react"
import NewExperienceCard, {NewExperienceFormType} from "../common/NewExperienceCard"
import {Toast} from "primereact/toast"
import {refreshOnExperienceListChange} from "../../utils/RefreshFunctions"
import usePageAnalytics from "../../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../../models/RouteFrontEnd"
import useRoleStatus from "../../hooks/useRoleStatus"
import {UserRoles} from "../../models/Names"
import {analyticsEvent, AnalyticsEventCategory} from "../../services/analytics/analytics"
import {candidateAppContext} from "../../pages/MainPageCandidate";

const CandidateNewExperience = () => {
    let {id} = useParams()
    let navigate = useNavigate()
    const {candidateData} = useContext(candidateAppContext)
    const userStatus = useRoleStatus([UserRoles.CANDIDATE, UserRoles.AGENCY, UserRoles.ADMIN])
    const queryByTokenType = !id ? getQueryNetwork : getQueryByTempTokenNetwork
    const candidate = useQuery([NetworkEndpoints.CANDIDATE_BY_ID, candidateData.id], queryByTokenType, {staleTime: 60000, enabled: !!id})
    const queryClient = useQueryClient()
    const {mutate} = useMutation({
        mutationFn: postExperienceNetwork,
        onSuccess: (data, variables, context) => {
            refreshOnExperienceListChange(queryClient, id) //Maybe it should use .then for navigate
            analyticsEvent(AnalyticsEventCategory.CREATE_EXPERIENCE, {
                role: userStatus.role,
                candidateId: id,
                experienceId: variables.experienceId
            })
            if(candidateData.isLogin) {
                navigate(`/${RouteFrontEnd.CANDIDATE}/${RouteFrontEnd.EXPERIENCES}/${data.insertedId}`)
            }else {
                navigate(`/${RouteFrontEnd.CANDIDATE}/${candidateData.id}/${RouteFrontEnd.EXPERIENCES}/${data.insertedId}`)
            }
        },
        onError: (error, variables, context) => {
            analyticsEvent(AnalyticsEventCategory.CREATE_EXPERIENCE, {
                role: userStatus.role,
                candidateId: id,
                experienceId: variables.experienceId,
                error: "Error creating experience"
            })
        }
    })

    const toast = useRef(null)

    usePageAnalytics("/"+RouteFrontEnd.CANDIDATE+"/"+id+"/experiences/new",'CandidateNewExperience')

    const postNewExperience = (values: NewExperienceFormType) => {
        mutate({
            candidateId: '' + candidate.data?._id,
            companyName: values.companyName,
            jobTitle: values.jobTitle,
            jobStartDate: values.jobStartDate,
            jobEndDate: values.jobEndDate,
            jobOngoing: values.jobOngoing,
            jobFullTime: values.jobFullTime,
            continuous: values.continuous,
            contractor: values.contractor,
            effectiveWorkMonths: values.effectiveWorkMonths,
            token: id
        })
        showSuccess()
    }

    const showSuccess = () => {
        // @ts-ignore
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Successfully added experience',
            life: 3000
        })
    }

    const header = <div className="font-bold text-l flex relative">
        <div onClick={() => navigate(-1)}><i className="pi pi-angle-left text-xl mr-2"></i>{candidate?.data?.name}</div>

    </div>

    return (
        <>
            <Toast ref={toast}/>
            <NewExperienceCard header={header} sendExperience={postNewExperience}/>
        </>
    )
}

export default CandidateNewExperience