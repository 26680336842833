
export enum RouteFrontEnd {
    AGENCIES = 'agencies',
    AGENCY = 'agency',
    NEW_AGENCY = 'agencies/new',
    EDIT_AGENCIES = 'agencies/edit',
    AGENCY_USERS = 'users',

    ADMIN_USERS = 'admin/users',

    INSPECTIONS = 'inspections',

    CANDIDATES = 'candidates',
    NEW_CANDIDATE = 'candidates/new',
    UPDATE_CANDIDATE = 'candidates/edit',

    EXPERIENCES = 'experiences',
    NEW_EXPERIENCE = 'experiences/new',
    UPDATE_EXPERIENCE = 'experiences/edit',

    MANAGER = 'manager',
    RESET_PASSWORD = 'password-reset',
    REGISTRATION = 'registration',

    CANDIDATE = 'candidate',
    ERROR_PAGE = 'error',
    LOGIN = '/',
    FORGOT_PASSWORD = 'forgot-password',

    CERTIFICATE = 'certificate',
}