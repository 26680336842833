import ExperienceCard from "../common/ExperienceCard"
import {useNavigate, useParams} from "react-router-dom"
import {useQuery} from "react-query"
import {NetworkEndpoints} from "../../models/NetworkEndpoints"
import {getQueryByTempTokenNetwork, getQueryNetwork} from "../../networks/getNetwork"
import {Experience} from "../../models/AllModels"
import {useContext, useEffect} from "react"
import {candidateAppContext} from "../../pages/MainPageCandidate"
import {ProgressSpinner} from "primereact/progressspinner";
import usePageAnalytics from "../../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../../models/RouteFrontEnd"
import {getCandidateDataFromToken} from "../../utils/getCandidateDataFromToken"

const CandidateExperienceDetails = () => {
    let {id} = useParams()
    let {experienceId} = useParams()
    const navigation = useNavigate()
    const {setCandidateData, candidateData} = useContext(candidateAppContext)
    const queryByTokenType = !id ? getQueryNetwork : getQueryByTempTokenNetwork
    const {
        isLoading,
            isError,
            error,
            data
    } = useQuery([NetworkEndpoints.CANDIDATE_BY_ID, candidateData?.id], queryByTokenType, {staleTime: 60000, enabled: !!candidateData?.id})

    useEffect(() => {
        if(candidateData.id.length >2) {
            //TODO: this just check if candidateData is already loaded
        }else {
            const result = getCandidateDataFromToken(id, false)  //TODO: Check if login is needed
            if ("role" in result) {
                setCandidateData(result)
            } else {
                navigation('/error') //TODO: Add token error page
            }
        }
    }, [id])


    usePageAnalytics("/"+RouteFrontEnd.CANDIDATE+"/"+candidateData?.id+"/experiences/"+experienceId, "Edit Candidate")

    const back = () => {
        if(candidateData.isLogin) {
            navigation('/candidate', {replace: true})
        }else {
            navigation('/candidate/'+id, {replace: true})
        }
    }

    const experience = data?.experiences?.filter((e: Experience) => (''+e._id) === experienceId).pop()

    if(isLoading) return <ProgressSpinner/>
    if(candidateData.id.length < 2) return <ProgressSpinner/>
    if(experience === undefined) return <ProgressSpinner/>
    return (
        <div>
            <ExperienceCard experience={experience} candidate={data} back={back} />
        </div>
    )
}

export default CandidateExperienceDetails