import {QueryClient} from "react-query"
import {NetworkEndpoints} from "../models/NetworkEndpoints"

export const refreshOnAgencyListChange = async (queryClient: QueryClient) => {
    await queryClient.invalidateQueries([NetworkEndpoints.ADMIN_AGENCIES])
}

export const refreshOnAgencyChange = async (queryClient: QueryClient) => {
    await queryClient.invalidateQueries(NetworkEndpoints.AGENCIES)
    await refreshOnAgencyListChange(queryClient)
}

export const refreshOnCandidateListChange = async (queryClient: QueryClient) => {
    await queryClient.invalidateQueries([NetworkEndpoints.CANDIDATES])
}

export const refreshOnCandidateChange = async (queryClient: QueryClient, candidateId?: string, tempToken?: string | undefined, ) => {
    await queryClient.invalidateQueries([NetworkEndpoints.CANDIDATE_BY_ID])
    // if (!!tempToken) {
    //     await queryClient.invalidateQueries([NetworkEndpoints.CANDIDATE_BY_ID, tempToken])
    // } else {
    //     await queryClient.invalidateQueries([NetworkEndpoints.CANDIDATE_BY_ID, candidateId])
    // }
    await refreshOnCandidateListChange(queryClient)
}
export const refreshOnExperienceListChange = async (queryClient: QueryClient, candidateId?: string, tempToken?: string | undefined ) => {
    await queryClient.invalidateQueries([NetworkEndpoints.EXPERIENCES])
    // await queryClient.invalidateQueries([NetworkEndpoints.EXPERIENCES, candidateId])
    await refreshOnCandidateChange(queryClient, candidateId, tempToken)
}

export const refreshOnExperienceChange = async (queryClient: QueryClient, candidateId?: string, experienceId?: string, tempToken?: string | undefined) => {
    if (!!tempToken) {
        await queryClient.invalidateQueries([NetworkEndpoints.EXPERIENCE_BY_ID, tempToken])
    } else {
        await queryClient.invalidateQueries([NetworkEndpoints.EXPERIENCE_BY_ID, experienceId])
    }
    await refreshOnExperienceListChange(queryClient, candidateId, tempToken)
}

export const refreshOnAgencyPendingList = async (queryClient: QueryClient, agencyId?: string) => {
    await queryClient.invalidateQueries([NetworkEndpoints.USER_PENDING_INVITE, agencyId])
    await refreshOnAgencyChange(queryClient)
}

export const refreshOnVerificationListChange = async (queryClient: QueryClient, candidateId?: string, experienceId?: string, tempToken?: string | undefined) => {
    await queryClient.invalidateQueries([NetworkEndpoints.VERIFICATION_BY_EXPERIENCE])
    await refreshOnExperienceListChange(queryClient, candidateId, tempToken)
}

export const refreshOnInspectionListChange = async (queryClient: QueryClient) => {
    await queryClient.invalidateQueries([NetworkEndpoints.ADMIN_INSPECTION])
}