import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {useFormik} from "formik";
import * as Yup from "yup";
import React, {useContext, useState} from "react";
import logo from "../../../resources/LogoSymbolOnly.png";
import {candidateAppContext} from "../../../pages/MainPageCandidate";
import {patchSetPassword} from "../../../networks/patchNetwork";
import {useMutation} from "react-query";
import {useNavigate} from "react-router-dom";
import {RouteFrontEnd} from "../../../models/RouteFrontEnd";

const CandidateRegistrationButton = () => {

    const navigate = useNavigate()
    const {setCandidateData, candidateData} = useContext(candidateAppContext)
    const [registrationDialogVisible, setRegistrationDialogVisible] = useState(false)
    const {mutate} = useMutation({
        mutationFn: patchSetPassword,
        onSuccess: () => {
            navigate(RouteFrontEnd.LOGIN)
        },
        onError: () => {
            alert('Registration has failed') // TODO: change to regular toast
        }
    })

    const handleRegistrationButtonClick = () => {
        setRegistrationDialogVisible(true)
    }

    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/

    const formik = useFormik({
        initialValues: {
            password: '',
            repeatedPassword: ''

        },
        onSubmit: (values) => {
            if (formik.values.password !== formik.values.repeatedPassword) {
                alert('Passwords do not match') // TODO: change to regular toast
            } else {
                mutate({newPassword: values.password, tempToken: candidateData.id})
                formik.resetForm()
                setRegistrationDialogVisible(false)
            }
        },
        validationSchema: Yup.object({
            password: Yup.string().required('No password provided.')
                .min(8, 'Password is too short - should be 8 chars minimum.')
                .matches(passwordRules, {message: "Please create a stronger password"}),
        })
    })

    const header = <h5 className="pt-2 pl-2">Register account</h5>

    return (
        <>
            <Button onClick={handleRegistrationButtonClick} className='bg-orange-500 active:bg-red-500 p-2'>
                Register account
            </Button>
            <Dialog header={header} visible={registrationDialogVisible} style={{width: '50vw'}}
                    onHide={() => setRegistrationDialogVisible(false)}>
                <form onSubmit={(e) => {
                    e.preventDefault()
                    formik.handleSubmit(e)
                }}>
                    <span className="p-float-label mt-6">
                        <div className="flex flex-row align-items-center">
                            <img src={logo} alt="logo" height="20" width="20" className="mr-3 mb-2 w-4rem h-4rem"/>
                            <h1>Username is your email</h1>
                        </div>
                    </span>
                    <span className="p-float-label mt-6">
                        <InputText id="password" type="password"
                                   {...formik.getFieldProps('password')}
                                   className="w-full"/>
                        <label htmlFor="password">Password</label>
                        <small id="password-error"
                               className="p-error block">{formik.errors && formik.errors.password}</small>
                    </span>
                    <span className="p-float-label mt-6">
                        <InputText id="repeatedPassword" type="password"
                                   {...formik.getFieldProps('repeatedPassword')}
                                   className="w-full"/>
                        <label htmlFor="repeatedPassword">Repeat Password</label>
                        <small id="repeatedPassword-error"
                               className="p-error block">{formik.errors && formik.errors.repeatedPassword}</small>
                    </span>
                    <Button type="submit" className="mt-6">Register</Button>
                </form>
            </Dialog>
        </>
    )
}

export default CandidateRegistrationButton