import zxcvbn from "zxcvbn";

export const PASSWORD_CUTOFF_LENGTH = 8  // Ensure these values match the backend
const PASSWORD_CUTOFF_SCORE = 2   // Ensure these values match the backend

export const isPasswordStrongEnough = (password: string): boolean => {
    return (
        password.length >= PASSWORD_CUTOFF_LENGTH &&
        zxcvbn(password).score >= PASSWORD_CUTOFF_SCORE
    )
}