import {baseAuthUrl, baseUrl, getToken} from "../utils/NetworkUtil"
import {NetworkEndpoints} from "../models/NetworkEndpoints"
import {DtoInvite, DtoResultVerification, DtoSendVerification} from "../models/DtoModels"

const patchNetwork = async <T>(myUrl: string, obj?: T, tempToken: any = undefined) => {
    var token = await getToken()
    if (!!tempToken) {
        token = tempToken
    }

    const response = await fetch(`${myUrl}`, {
        method: 'PATCH',
        body: JSON.stringify(obj),
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!')
    }
    return await response.json()
}

export const patchSendVerification = async (params: DtoSendVerification) => {
    const url = `${baseUrl}/credentials/verifications/${params.verificationId}`
    return patchNetwork(url, {"refereeEmail": params.refereeEmail}, params?.tempToken)
}

export const patchSendVerificationByReferee = async (params: DtoResultVerification) => {
    const url = baseUrl+"/"+NetworkEndpoints.VERIFICATION + "/result"
    return patchNetwork(url, {
        "accepted": params.accepted,
        "refereeJobTitle": params.refereeJobTitle,
        "refereeComment": params.refereeComment
    }, params.token)
}

export const patchInspectionResult = async (params: {id:string, status: boolean}) => {
    const url = baseUrl+"/"+NetworkEndpoints.VERIFICATION+"/"+params.id+"/inspection"
    return patchNetwork(url, {"accepted": params.status})
}

export const patchInviteCandidate = async (params: {id:string, email: string}) => {
    const url = baseUrl+"/"+NetworkEndpoints.INVITATION_CANDIDATE+"/"+params.id
    return patchNetwork(url, {"email": params.email})
}

export const patchCandidateConsent = async (params: {tempToken: string}) => {
    const url = baseUrl+"/"+NetworkEndpoints.CONSENT_CANDIDATE
    return patchNetwork(url, {}, params.tempToken)
}

export const patchAgencyUserInvite = async (dto:DtoInvite) => {
    const url = baseUrl+"/"+NetworkEndpoints.USER_INVITE+"/"+dto.agencyId+"/internal"
    return patchNetwork(url, {
        email: dto.email,
    })
}

export const patchResetPasswordInvite = async (params: {email: string}) => {
    const url = baseAuthUrl+"/"+NetworkEndpoints.PASSWORD_RESET_INVITE
    return patchNetwork(url, {"email": params.email})
}
export const patchResetPassword = async (params: {newPassword: string, tempToken: string}) => {
    const url = baseAuthUrl+"/"+NetworkEndpoints.PASSWORD_RESET
    return patchNetwork(url, {"password": params.newPassword}, params.tempToken)
}

export const patchSetPassword = async (params: {newPassword: string, tempToken: string}) => {
    const url = baseAuthUrl+"/"+NetworkEndpoints.PASSWORD_SET
    return patchNetwork(url, {"password": params.newPassword}, params.tempToken)
}

export const patchAdminInvite = async (params: {email: string}) => {
    const url = baseAuthUrl+"/"+NetworkEndpoints.ADMIN_ADMIN_INVITE+"/"+params.email+"/internal"
    return patchNetwork(url)
}

