import {useNavigate} from "react-router-dom"
import useRoleStatus from "../../../hooks/useRoleStatus"
import {UserRoles} from "../../../models/Names"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"
import logo from "../../../resources/LogoSymbolOnly.png";
import React, {useContext, useEffect, useState} from "react";
import LogoutButton from "../LogoutButton";
import {Menubar} from "primereact/menubar";
import {candidateAppContext} from "../../../pages/MainPageCandidate";
import CandidateRegistrationButton from "./CandidateRegistrationButton";
import PleaseLoginButton from "./PleaseLoginButton";
import {getQueryByTempTokenNetwork, getQueryNetwork} from "../../../networks/getNetwork";
import {useQuery} from "react-query";
import {NetworkEndpoints} from "../../../models/NetworkEndpoints";

const CandidateTopMenu = () => {
    const {candidateData, setCandidateData} = useContext(candidateAppContext)
    const navigate = useNavigate()
    const roleStatus = useRoleStatus([UserRoles.CANDIDATE])
    const [showButtons, setShowButtons] = useState(false);
    const queryByTokenType = candidateData.isLogin ? getQueryNetwork : getQueryByTempTokenNetwork
    const {
        isLoading,
        data
    } = useQuery([NetworkEndpoints.CANDIDATE_BY_ID, candidateData.id], queryByTokenType, {staleTime: 60000, enabled: !!candidateData.id})

    useEffect(() => {
        if(data) {
            // @ts-ignore
            setCandidateData(prev => ({...prev, hasLogin: data?.hasLogin}))
        }
    }, [data, setCandidateData])

    useEffect(() => {
        if (candidateData.hasLogin) {
            // If hasLogin is true, show the buttons immediately
            setShowButtons(true);
        } else {
            // Otherwise, wait for 500ms before showing the buttons
            const timer = setTimeout(() => {
                setShowButtons(true);
            }, 500);

            // Cleanup the timer if the component unmounts before the delay is over
            return () => clearTimeout(timer);
        }
    }, [candidateData.hasLogin]);

    const candidateTopMenuItemsStart = [
        {
            icon: 'pi pi-plus',
            command: () => navigate(RouteFrontEnd.NEW_EXPERIENCE)
        }
    ]

    const start = <img
        src={logo}
        alt="logo"
        height="40"
        className="mr-2 h-4rem"
        style={{cursor: 'pointer'}}
    ></img>

    const end = <div style={{
        visibility: showButtons ? 'visible' : 'hidden',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '1rem'
    }} className="flex flex-row card-container">
        {candidateData.isLogin ? (
            <LogoutButton />
        ) : candidateData.hasLogin ? (
            <PleaseLoginButton />
        ) : (
            <CandidateRegistrationButton />
        )}
    </div>

    return (
        <>
            <Menubar model={candidateTopMenuItemsStart} start={start} end={end}/>
        </>
    )
}

export default CandidateTopMenu