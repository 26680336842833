import {useNavigate} from "react-router-dom"
import {RouteFrontEnd} from "../../../models/RouteFrontEnd"


const PleaseLoginButton = () => {
    const navigate = useNavigate()

    return (
        <button
            className="p-button p-button-text"
            onClick={() => {
                navigate(RouteFrontEnd.LOGIN)
            }}
        >
            Please login
        </button>
    )
}

export default PleaseLoginButton