import jwtDecode from "jwt-decode"
import {CandidateContextType} from "../pages/MainPageCandidate"

export const getCandidateDataFromToken = (id: string | undefined | null, login: boolean): CandidateContextType | Error => {
    let token: any | undefined = undefined
    if (!!id) {
        token = jwtDecode(id)
    }
    if (!!id && !!token && token.exp !== undefined && token.exp !== 0) {
        const currentTime = Math.floor(Date.now() / 1000)
        if (token.exp < currentTime) {
           return new Error('Token expired')
        } else {
            return {
                id: id,
                candidateId: token.permissions.candidateId,
                role: token.role,
                exp: token.exp,
                isConsentGiven: false,
                isLogin: login,
                hasLogin: false
            }
        }
    } else {
        return new Error('Token is not valid')
    }
}